import type { AccountInfo } from "@azure/msal-browser";
import * as Sentry from "@sentry/browser";

export interface LoggedInCustomer {
  loggedIn: true;
  id: string | undefined;
  emailAddress: string;
}
export interface LoggedOutCustomer {
  loggedIn: false;
}

export const mapAccountInfoToLoggedInCustomer = (
  account: AccountInfo,
): LoggedInCustomer => {
  const id = account.idTokenClaims?.extension_customerId as unknown;
  const idExists = id && typeof id === "string";
  const emailAddress = account.username;

  if (!idExists) {
    const strippedIdTokenClaims = {
      ...account.idTokenClaims,
      at_hash: undefined,
      auth_time: undefined,
      aud: undefined,
      iat: undefined,
      nbf: undefined,
      emails: undefined,
      nonce: undefined,
      sub: undefined,
    };

    Sentry.captureException(new Error("Couldn't retrieve customerId"), {
      contexts: {
        account: {
          customerIdType: typeof id,
          customerId: id || "not defined",
          idTokenClaimsExists: Boolean(account.idTokenClaims),
          claims: strippedIdTokenClaims,
        },
      },
    });
  }

  return {
    loggedIn: true,
    id: idExists ? id : undefined,
    emailAddress,
  };
};
